import type { LinksFunction, LoaderFunction } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
} from '@remix-run/react';
import { useChangeLanguage } from 'remix-i18next/react';
import { withSentry } from '@sentry/remix';
import { useTranslation } from 'react-i18next';
import './app.css';
import { Toaster } from './components/ui/toaster';
import { Toaster as Sonner } from './components/ui/sonner';
import sonnerStyles from '~/styles/sonner.css?url';
import stylesheet from '~/globals.css?url';
import leafletStyleShet from '~/leaflet.css?url';
import fullcalendarStylesheet from '~/fullcalendar.css?url';
import i18next from './i18next.server';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  {
    rel: 'stylesheet',
    href: leafletStyleShet,
  },
  { rel: 'stylesheet', href: fullcalendarStylesheet },
  { rel: 'stylesheet', href: sonnerStyles },
];

export const loader: LoaderFunction = async ({ request }) => {
  const locale = await i18next.getLocale(request);
  return json({
    locale,
    ENV: {
      NODE_ENV: process.env.NODE_ENV,
      HASURA_GRAPHQL_URI: process.env.HASURA_GRAPHQL_URI,
      HASURA_GRAPHQL_WS_URI: process.env.HASURA_GRAPHQL_WS_URI,
      S3_BUCKET_URL: process.env.S3_BUCKET_URL,
      S3_BUCKET_NAME: process.env.S3_BUCKET_NAME,
      S3_BUCKET_CDN_URL: process.env.S3_BUCKET_CDN_URL,
      S3_PRIVATE_BUCKET_URL: process.env.S3_PRIVATE_BUCKET_URL,
      S3_PRIVATE_BUCKET_NAME: process.env.S3_PRIVATE_BUCKET_NAME,
      S3_BUCKET_REGION: process.env.S3_BUCKET_REGION,
      TAXES_VALUE: process.env.TAXES_VALUE,
      STRIPE_PUBLIC_KEY: process.env.STRIPE_PUBLIC_KEY,
      STRIPE_CONNECT_ONBOARDING_ENABLED:
        process.env.STRIPE_CONNECT_ONBOARDING_ENABLED,
      GOOGLE_PLACES_API_KEY: process.env.GOOGLE_PLACES_API_KEY,
      GOOGLE_RECAPTCHA_SITE_KEY: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
      ADDRESS_AUTOCOMPLETE_PROVIDER: process.env.ADDRESS_AUTOCOMPLETE_PROVIDER,
      WEB_URL: process.env.WEB_URL,
      GTAG_ID: process.env.GTAG_ID,
      HOTJAR_ID: process.env.HOTJAR_ID,
    },
  });
};

export const handle = {
  i18n: 'common',
};

function App() {
  const { locale, ENV } = useLoaderData<typeof loader>();
  const { i18n } = useTranslation();

  useChangeLanguage(locale);

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
        <Meta />
        <style data-fullcalendar />
        <Links />
        {ENV.NODE_ENV === 'production' && (
          <>
            {/* Google tag (gtag.js) */}
            {ENV.GTAG_ID && (
              <>
                <script
                  async
                  src={`https://www.googletagmanager.com/gtag/js?id=${ENV.GTAG_ID}`}
                />
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${ENV.GTAG_ID}');
                    `,
                  }}
                />
              </>
            )}
            {/* Hotjar Tracking Code */}
            {ENV.HOTJAR_ID && (
              <script
                dangerouslySetInnerHTML={{
                  __html: `
                    (function(h,o,t,j,a,r){
                      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                      h._hjSettings={hjid:${ENV.HOTJAR_ID},hjsv:6};
                      a=o.getElementsByTagName('head')[0];
                      r=o.createElement('script');r.async=1;
                      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                      a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                  `,
                }}
              />
            )}
          </>
        )}
      </head>
      <body className="overflow-x-hidden font-fieldwork antialiased">
        <Outlet />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        <Scripts />
        <Sonner />
        <Toaster />
      </body>
    </html>
  );
}

export default withSentry(App);
