import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      theme="light"
      className="toaster group"
      position="top-right"
      offset={82}
      closeButton={false}
      toastOptions={{
        unstyled: true,
        classNames: {
          toast:
            'w-80 h-24 p-4 rounded-s-lg border-l-2 border-l-destructive bg-gradient-to-r from-destructive/5 shadow-2xl',
          title: 'text-red-400',
          description: 'text-red-400',
          actionButton: 'bg-zinc-400',
          cancelButton: 'bg-orange-400',
          closeButton: 'bg-lime-400',
          success: 'bg-success-foreground text-success',
          error: 'bg-destructive-foreground text-destructive',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
