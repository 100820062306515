import {
  RiCheckboxCircleLine,
  RiInformationLine,
  RiLock2Line,
} from '@remixicon/react';
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
  toastVariants,
} from './toast';
import { useToast } from './use-toast';
import { VariantProps } from 'class-variance-authority';

export function Toaster() {
  const { toasts } = useToast();

  const iconSwitch = (
    variant: VariantProps<typeof toastVariants>['variant'],
  ) => {
    switch (variant) {
      case 'default':
        return <RiLock2Line size={20} />;
      case 'info':
        return <RiInformationLine size={20} />;
      case 'warning':
        return <RiInformationLine size={20} />;
      case 'success':
        return <RiCheckboxCircleLine size={20} />;
      case 'destructive':
        return <RiInformationLine size={20} />;
      default:
        return <RiLock2Line size={20} />;
    }
  };

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        // variant,
        ...props
      }) {
        return (
          <Toast key={id} {...props}>
            <div className="flex gap-4">
              {iconSwitch(props?.variant)}
              <div>
                {title && (
                  <ToastTitle className="pb-3 text-base font-bold">
                    {title}
                  </ToastTitle>
                )}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
